const talkData = [
  {
    title: "Operators and Relativisms",
    event: "Syntactic Arguments in the Contextualism/Relativism Debate",
    location: "University of Warsaw",
    date: "May 17, 2023 ",
  },
  {
    title: "Naming and variability",
    event: "50 Years of Naming and Necessity",
    location: "City University of New York",
    date: "December 13, 2022",
  },
  {
    title: "Pure quotation in linguistic context",
    event: "Pacific APA",
    location: "Vancouver, BC",
    date: "April 13-16, 2022",
  },
  {
    title: "The Syntax and Semantics of Wire Diagrams: Two Approaches",
    event: "Diagrams 2021",
    location: "online",
    date: "Sept. 29, 2021",
  },
  {
    title: "Against Fregean Quantification",
    event: "Pacific APA, Invited session (Commentators: R. May & K. Wehmeier)",
    location: "online",
    date: "April 2021",
  },
  {
    title: "Against Fregean Quantification",
    event: "Lingnan University and the University of Hong Kong",
    date: "June 29, 2020",
    location: "online",
  },
  {
    title: "Against Fregean Quantification",
    event: "Arché Language and Mind Seminar",
    date: "May 26, 2020",
    location: "online",
  },
  {
    title: "The myth of occurrence-based semantics",
    event: "Pacific APA, Colloquium session",
    location: "San Francisco",
    date: "Cancelled due to pandemic",
  },
  {
    title: "Semantic monsters",
    event: "Undergraduate Linguistics Association of Britain",
    location: "Lancaster University",
    date: "February 5, 2020",
  },
  {
    title: "The paradox of self-blame",
    event: "Workshop on Blame and Responsibility",
    location: "University of Edinburgh",
    date: "April 8, 2019",
  },
  {
    title: "Against Fregean Predicate Logic",
    event: "Workshop on Identity and Quantification",
    location: "University of Birmingham",
    date: "December 17-18, 2018",
  },
  {
    title: "Toward a solution to the nesting problem for two-dimensionalism",
    event: "Workshop on Philosophical Applications of Modal Logic",
    location: "University of Oslo",
    date: "June 13-14, 2018",
  },
  {
    title: "Variables and quantification",
    event: "Kompaktseminar in Logik (7)",
    location: "Göttingen, Germany",
    date: "March 20-23, 2018",
  },
  {
    title: "Symposium: Silke's 'Semantics with Assignment Variables'",
    event: "Pacific APA",
    date: "March 29, 2018",
    location: "San Diego, CA",
  },
  {
    title: "Future contingents and temporal omniscience",
    event: "Dartmouth Sapientia Lecture Series",
    location: "Hanover, NH",
    date: "August 8, 2017",
  },
  {
    title: "The myth of occurrence-based semantics",
    event: "Context and variables workshop",
    location: "All Souls College, Oxford",
    date: "May 13, 2017",
  },
  {
    title: "Future contingents and the retrospections of God",
    event: "Edinburgh Language Workshop 2",
    date: "December 1, 2016",
  },
  {
    title: "A bridge from semantic value to content",
    event: "Glasgow University Philosophy Society",
    date: "November 2, 2016",
  },
  {
    title: "A bridge from semantic value to content",
    event: "The Jowett Society",
    date: "October 21, 2016",
    location: "Oxford University, Oxford, UK",
  },
  {
    title: "The myth of occurrence-based semantics",
    event: "What is Said--What is Meant",
    location: "Berlin, Germany",
    date: "September 9-13, 2016",
  },
  {
    title: "The myth of occurrence-based semantics",
    event: "Mid-Atlantic Philosophy of Language Workshop",
    location: "Morgantown, WV",
    date: "August 4, 2016",
  },
  {
    title:
      "Quantifiers vs intensional operators: a distinction without difference",
    event: "Workshop on Operators vs Quantifiers",
    location: "Barcelona, Spain",
    date: "July 1, 2016",
  },
  {
    title: "Binding across quantifiers concealed in diamonds",
    event: "MCMP Colloquium in Mathematical Philosophy",
    location: "Munich, Germany",
    date: "June 9, 2016",
  },
  {
    title: "Binding the bound",
    event: "Edinburgh Language Workshop",
    location: "Edinburgh, Scotland",
    date: "May 9, 2016",
  },
  {
    title: "Variables under epistemic modals",
    event: "St. Andrews Philosophy Club",
    date: "March 30, 2016",
    location: "St. Andrews, Scotland",
  },
  {
    title: "Variables under epistemic modals",
    event: "VSS Staff Seminar",
    location: "Edinburgh, Scotland",
    date: "November 27, 2015",
  },
  {
    title: "Variables under epistemic modals",
    event: "Toronto M&E Group",
    location: "University of Toronto",
    date: "October 23, 2015",
  },
  {
    title: "A solution to the problem of recurring demonstratives",
    event: "Pacific APA",
    location: "Vancouver, Canada",
    date: "April 1, 2015",
  },
  {
    title: "Semantic pluralism and the meaning of 'x'",
    event: "The Semantic Pluralism Workshop",
    location: "Konstanz, Germany",
    date: "January 19th, 2015",
  },
  {
    title: "The compositionality argument for temporalism",
    event: "Arché Tense in Semantics and Philosophy of Language Workshop",
    date: "June 5, 2014",
    location: "St. Andrews, Scotland",
  },
  {
    title: "Propositional content under contextual shifting and updating",
    event: "Metasemantics Workshop",
    date: "March 9, 2014",
    location: "UC Berkley, CA",
  },
  {
    title: "The antinomy of the variable: renewed and resolved",
    event: "Institute of Philosophy, LEM Forum",
    date: "November 11, 2013",
    location: "London, UK",
  },
  {
    title: "Tenses, sentences, and schmentences",
    event: "Workshop on Tense, Modality, and Semantic Values",
    location: "University of Oslo, Norway",
    date: "June 2013",
  },
  {
    title: "Propositions and reference in natural language semantics",
    event: "Arché Philosophy of Semantics Workshop",
    location: "St. Andrews, Scotland",
    date: "May 2013",
  },
  {
    title: "Embedding and the objects of assertion",
    location: "University of Aberdeen, Scotland",
    date: "July 10, 2012",
  },
  {
    title: "Descriptions which have grown capital letters",
    event: "Pacific APA",
    location: "Seattle, WA",
    date: "April 5, 2012",
  },
  {
    title: "Assertoric content and variable binding",
    event: "Invited talk",
    location: "Leeds University, Leeds, UK",
    date: "February 12, 2012",
  },
  {
    title: "Two-dimensionalism and the argument from binding",
    event: "Invited talk",
    location: "Northern Illinois University, DeKalb, IL",
    date: "January 20, 2012",
  },
  {
    title: "Monsters in Kaplan's Logic of Demonstratives",
    event: "Eastern APA",
    location: "Washington, DC",
    date: "December 28, 2011",
  },
  {
    title: "Semantic content and variable binding",
    event: "3rd Semantic Content Workshop",
    location: "Barcelona, Spain",
    date: "November 4, 2011",
  },
  {
    title: "Natural language semantics and propositions",
    event: "ANU Philosophy Society",
    location: "Canberra, Australia",
    date: "March 15, 2011",
  },
  {
    title: "Propositional content and compositionality",
    event: "Propositions and Same-Saying workshop",
    location: "Sydney, Australia",
    date: "July 21, 2010",
  },
  {
    title: "Embedding indexicals",
    event: "Australasian Association of Philosophy",
    location: "Sydney, Australia",
    date: "July 3, 2010",
  },
  {
    title: "A puzzle about names",
    event: "ANU Philosophy Society",
    location: "Canberra, Australia",
    date: "September 3, 2010",
  },
  {
    title: "Monsters in the semantics",
    event: "Friday Seminar",
    location: "St. Andrews, Scotland",
    date: "November 11, 2009",
  },
  {
    title: "Why double indexing?",
    event: "Australasian Association of Philosophy",
    location: "Melbourne, Australia",
    date: "July 7, 2009",
  },
  {
    title: "The character of names and epistemic monsters",
    event: "ANU Philosophy Society",
    location: "Canberra, Australia",
    date: "December 3, 2008",
  },
  {
    title: "Is direct reference a pragmatic phenomenon?",
    event: "Australasian Association of Philosophy",
    location: "Melbourne, Australia",
    date: "June 30, 2008",
  },
  {
    title: "The names as predicates hypothesis",
    event: "Kioloa Workshop",
    location: "New South Wales, Australia",
    date: "2008",
  },
];

export default talkData;

const landonPublicationsData = [
  {
    note: "Special issue in honour of Landon Rabern",
    url: "https://www.sciencedirect.com/science/article/pii/S0012365X22005064",
    doi: "10.1016/j.disc.2022.113300",
    pages: "113300",
    number: "11",
    volume: "346",
    journal: "Discrete Mathematics",
    year: "2023",
    author: "Ilkyoo Choi and H.A. Kierstead and Landon Rabern",
    title:
      "The list version of the Borodin-Kostochka conjecture for graphs with large maximum degree",
    ENTRYTYPE: "article",
    ID: "choi2023list",
    bibtex:
      "@article{choi2023list,\n    note = {Special issue in honour of Landon Rabern},\n    url = {https://www.sciencedirect.com/science/article/pii/S0012365X22005064},\n    doi = {10.1016/j.disc.2022.113300},\n    pages = {113300},\n    number = {11},\n    volume = {346},\n    journal = {Discrete Mathematics},\n    year = {2023},\n    author = {Ilkyoo Choi and H.A. Kierstead and Landon Rabern},\n    title = {The list version of the Borodin-Kostochka conjecture for graphs with large maximum degree}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1145/3583133.3596367",
    doi: "10.1145/3583133.3596367",
    pages: "1721--1726",
    booktitle:
      "Proceedings of the Companion Conference on Genetic and Evolutionary Computation",
    year: "2023",
    author:
      "Kopito, Gabriel and Schwartz, Jonathan and Amblard, Julien and Filman, Robert and Rabern, Landon",
    title: "MLStar: A System for Synthesis of Machine-Learning Programs",
    ENTRYTYPE: "inproceedings",
    ID: "kopito2023mlstar",
    bibtex:
      "@inproceedings{kopito2023mlstar,\n    url = {https://doi.org/10.1145/3583133.3596367},\n    doi = {10.1145/3583133.3596367},\n    pages = {1721--1726},\n    booktitle = {Proceedings of the Companion Conference on Genetic and Evolutionary Computation},\n    year = {2023},\n    author = {Kopito, Gabriel and Schwartz, Jonathan and Amblard, Julien and Filman, Robert and Rabern, Landon},\n    title = {MLStar: A System for Synthesis of Machine-Learning Programs}\n}\n\n",
  },
  {
    note: "arXiv:1409.6812",
    url: "https://www.sciencedirect.com/science/article/pii/S0012365X22004678",
    doi: "10.1016/j.disc.2022.113261",
    pages: "113261",
    number: "11",
    volume: "346",
    journal: "Discrete Mathematics",
    year: "2023",
    author: "Landon Rabern",
    title: "Yet another proof of Brooks' theorem",
    ENTRYTYPE: "article",
    ID: "rabern2023another",
    bibtex:
      "@article{rabern2023another,\n    note = {arXiv:1409.6812},\n    url = {https://www.sciencedirect.com/science/article/pii/S0012365X22004678},\n    doi = {10.1016/j.disc.2022.113261},\n    pages = {113261},\n    number = {11},\n    volume = {346},\n    journal = {Discrete Mathematics},\n    year = {2023},\n    author = {Landon Rabern},\n    title = {Yet another proof of Brooks' theorem}\n}\n\n",
  },
  {
    url: "https://arxiv.org/pdf/2006.02015.pdf",
    doi: "10.1002/jgt.22845",
    pages: "633--642",
    number: "4",
    volume: "101",
    publisher: "Wiley Online Library",
    journal: "Journal of Graph Theory",
    year: "2022",
    author: "Cranston, Daniel W and Lafayette, Hudson and Rabern, Landon",
    title: "Coloring (P_5, gem)-free graphs with Delta - 1 colors",
    ENTRYTYPE: "article",
    ID: "cranston2022coloring",
    bibtex:
      "@article{cranston2022coloring,\n    url = {https://arxiv.org/pdf/2006.02015.pdf},\n    doi = {10.1002/jgt.22845},\n    pages = {633--642},\n    number = {4},\n    volume = {101},\n    publisher = {Wiley Online Library},\n    journal = {Journal of Graph Theory},\n    year = {2022},\n    author = {Cranston, Daniel W and Lafayette, Hudson and Rabern, Landon},\n    title = {Coloring (P$_5$, gem)-free graphs with $\\Delta - 1$ colors}\n}\n\n",
  },
  {
    url: "https://arxiv.org/abs/2104.04626",
    journal: "arXiv preprint arXiv:2104.04626",
    year: "2021",
    author: "Rabern, Brian and Rabern, Landon",
    title: "Structural fixed-point theorems",
    ENTRYTYPE: "article",
    ID: "rabern2021structural",
    bibtex:
      "@article{rabern2021structural,\n    url = {https://arxiv.org/abs/2104.04626},\n    journal = {arXiv preprint arXiv:2104.04626},\n    year = {2021},\n    author = {Rabern, Brian and Rabern, Landon},\n    title = {Structural fixed-point theorems}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1016/j.jctb.2019.05.004",
    doi: "10.1016/j.jctb.2019.05.004",
    pages: "147--170",
    volume: "140",
    journal: "Journal of Combinatorial Theory, Series B",
    year: "2020",
    author: "Kierstead, H.A. and Rabern, Landon",
    title:
      "Improved lower bounds on the number of edges in list critical and online list critical graphs",
    ENTRYTYPE: "article",
    ID: "kierstead2020improved",
    bibtex:
      "@article{kierstead2020improved,\n    url = {https://doi.org/10.1016/j.jctb.2019.05.004},\n    doi = {10.1016/j.jctb.2019.05.004},\n    pages = {147--170},\n    volume = {140},\n    journal = {Journal of Combinatorial Theory, Series B},\n    year = {2020},\n    author = {Kierstead, H.A. and Rabern, Landon},\n    title = {Improved lower bounds on the number of edges in list critical and online list critical graphs}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1137/18M117056X",
    doi: "10.1137/18M117056X",
    pages: "1228--1241",
    number: "3",
    volume: "33",
    journal: "SIAM Journal on Discrete Mathematics",
    year: "2019",
    author: "Cranston, Daniel W. and Rabern, Landon",
    title:
      "The Hilton-Zhao Conjecture is True for Graphs with Maximum Degree 4",
    ENTRYTYPE: "article",
    ID: "cranston2019hilton",
    bibtex:
      "@article{cranston2019hilton,\n    url = {https://doi.org/10.1137/18M117056X},\n    doi = {10.1137/18M117056X},\n    pages = {1228--1241},\n    number = {3},\n    volume = {33},\n    journal = {SIAM Journal on Discrete Mathematics},\n    year = {2019},\n    author = {Cranston, Daniel W. and Rabern, Landon},\n    title = {The Hilton-Zhao Conjecture is True for Graphs with Maximum Degree 4}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1007/s00493-016-3584-6",
    doi: "10.1007/s00493-016-3584-6",
    pages: "1045--1065",
    number: "5",
    volume: "38",
    journal: "Combinatorica",
    year: "2018",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "Edge lower bounds for list critical graphs, via discharging",
    ENTRYTYPE: "article",
    ID: "cranston2018edge",
    bibtex:
      "@article{cranston2018edge,\n    url = {https://doi.org/10.1007/s00493-016-3584-6},\n    doi = {10.1007/s00493-016-3584-6},\n    pages = {1045--1065},\n    number = {5},\n    volume = {38},\n    journal = {Combinatorica},\n    year = {2018},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {Edge lower bounds for list critical graphs, via discharging}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1016/j.jctb.2018.04.002",
    doi: "10.1016/j.jctb.2018.04.002",
    pages: "32--45",
    volume: "133",
    journal: "Journal of Combinatorial Theory, Series B",
    year: "2018",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "Planar graphs are 9/2-colorable",
    ENTRYTYPE: "article",
    ID: "cranston2018planar",
    bibtex:
      "@article{cranston2018planar,\n    url = {https://doi.org/10.1016/j.jctb.2018.04.002},\n    doi = {10.1016/j.jctb.2018.04.002},\n    pages = {32--45},\n    volume = {133},\n    journal = {Journal of Combinatorial Theory, Series B},\n    year = {2018},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {Planar graphs are 9/2-colorable}\n}\n\n",
  },
  {
    url: "https://doi.org/10.37236/6405",
    doi: "10.37236/6405",
    pages: "P1.51",
    number: "1",
    volume: "25",
    journal: "The Electronic Journal of Combinatorics",
    year: "2018",
    author: "Rabern, Landon",
    title: "A better lower bound on average degree of k-list-critical graphs",
    ENTRYTYPE: "article",
    ID: "rabern2018better",
    bibtex:
      "@article{rabern2018better,\n    url = {https://doi.org/10.37236/6405},\n    doi = {10.37236/6405},\n    pages = {P1.51},\n    number = {1},\n    volume = {25},\n    journal = {The Electronic Journal of Combinatorics},\n    year = {2018},\n    author = {Rabern, Landon},\n    title = {A better lower bound on average degree of $k$-list-critical graphs}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1007/s00493-016-3380-3",
    doi: "10.1007/s00493-016-3380-3",
    pages: "837--861",
    number: "5",
    volume: "37",
    journal: "Combinatorica",
    year: "2017",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "The fractional chromatic number of the plane",
    ENTRYTYPE: "article",
    ID: "cranston2017fractional",
    bibtex:
      "@article{cranston2017fractional,\n    url = {https://doi.org/10.1007/s00493-016-3380-3},\n    doi = {10.1007/s00493-016-3380-3},\n    pages = {837--861},\n    number = {5},\n    volume = {37},\n    journal = {Combinatorica},\n    year = {2017},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {The fractional chromatic number of the plane}\n}\n\n",
  },
  {
    url: "10.1137/15M1051774",
    pages: "726--748",
    number: "2",
    volume: "31",
    journal: "SIAM Journal on Discrete Mathematics",
    year: "2017",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "List-Coloring Claw-Free Graphs with Delta-1 Colors",
    ENTRYTYPE: "article",
    ID: "cranston2017list",
    bibtex:
      "@article{cranston2017list,\n    url = {10.1137/15M1051774},\n    pages = {726--748},\n    number = {2},\n    volume = {31},\n    journal = {SIAM Journal on Discrete Mathematics},\n    year = {2017},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {List-Coloring Claw-Free Graphs with $\\Delta$-1 Colors}\n}\n\n",
  },
  {
    url: "10.37236/6179",
    pages: "P3.29",
    number: "3",
    volume: "24",
    journal: "The Electronic Journal of Combinatorics",
    year: "2017",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "Beyond Degree Choosability",
    ENTRYTYPE: "article",
    ID: "cranston2017degree",
    bibtex:
      "@article{cranston2017degree,\n    url = {10.37236/6179},\n    pages = {P3.29},\n    number = {3},\n    volume = {24},\n    journal = {The Electronic Journal of Combinatorics},\n    year = {2017},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {Beyond Degree Choosability}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1002/jgt.22116",
    doi: "10.1002/jgt.22116",
    pages: "122--136",
    number: "1",
    volume: "86",
    journal: "Journal of Graph Theory",
    year: "2017",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "Subcubic edge-chromatic critical graphs have many edges",
    ENTRYTYPE: "article",
    ID: "cranston2017subcubic",
    bibtex:
      "@article{cranston2017subcubic,\n    url = {https://doi.org/10.1002/jgt.22116},\n    doi = {10.1002/jgt.22116},\n    pages = {122--136},\n    number = {1},\n    volume = {86},\n    journal = {Journal of Graph Theory},\n    year = {2017},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {Subcubic edge-chromatic critical graphs have many edges}\n}\n\n",
  },
  {
    url: "10.1137/16M1099030",
    pages: "2039--2063",
    number: "3",
    volume: "31",
    journal: "SIAM Journal on Discrete Mathematics",
    year: "2017",
    author: "Cranston, Daniel W. and Rabern, Landon",
    title: "Short fans and the 5/6 bound for line graphs",
    ENTRYTYPE: "article",
    ID: "cranston2017short",
    bibtex:
      "@article{cranston2017short,\n    url = {10.1137/16M1099030},\n    pages = {2039--2063},\n    number = {3},\n    volume = {31},\n    journal = {SIAM Journal on Discrete Mathematics},\n    year = {2017},\n    author = {Cranston, Daniel W. and Rabern, Landon},\n    title = {Short fans and the 5/6 bound for line graphs}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1002/jgt.22128",
    doi: "10.1002/jgt.22128",
    pages: "315--328",
    number: "3",
    volume: "86",
    journal: "Journal of Graph Theory",
    year: "2017",
    author: "Kierstead, H.A. and Rabern, Landon",
    title: "Extracting list colorings from large independent sets",
    ENTRYTYPE: "article",
    ID: "kierstead2017extracting",
    bibtex:
      "@article{kierstead2017extracting,\n    url = {https://doi.org/10.1002/jgt.22128},\n    doi = {10.1002/jgt.22128},\n    pages = {315--328},\n    number = {3},\n    volume = {86},\n    journal = {Journal of Graph Theory},\n    year = {2017},\n    author = {Kierstead, H.A. and Rabern, Landon},\n    title = {Extracting list colorings from large independent sets}\n}\n\n",
  },
  {
    url: "https://doi.org/10.37236/5309",
    doi: "10.37236/5309",
    pages: "P3.45",
    number: "3",
    volume: "23",
    journal: "The Electronic Journal of Combinatorics",
    year: "2016",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "Planar Graphs have Independence Ratio at least 3/13",
    ENTRYTYPE: "article",
    ID: "cranston2016planar",
    bibtex:
      "@article{cranston2016planar,\n    url = {https://doi.org/10.37236/5309},\n    doi = {10.37236/5309},\n    pages = {P3.45},\n    number = {3},\n    volume = {23},\n    journal = {The Electronic Journal of Combinatorics},\n    year = {2016},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {Planar Graphs have Independence Ratio at least 3/13}\n}\n\n",
  },
  {
    url: "https://doi.org/10.37236/4978",
    doi: "10.37236/4978",
    pages: "P2.50",
    number: "2",
    volume: "23",
    journal: "The Electronic Journal of Combinatorics",
    year: "2016",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "Painting Squares in Delta^{2}-1 Shades",
    ENTRYTYPE: "article",
    ID: "cranston2016painting",
    bibtex:
      "@article{cranston2016painting,\n    url = {https://doi.org/10.37236/4978},\n    doi = {10.37236/4978},\n    pages = {P2.50},\n    number = {2},\n    volume = {23},\n    journal = {The Electronic Journal of Combinatorics},\n    year = {2016},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {Painting Squares in $\\Delta^{2}-1$ Shades}\n}\n\n",
  },
  {
    url: "https://doi.org/10.37236/5971",
    doi: "10.37236/5971",
    pages: "P3.37",
    number: "3",
    volume: "23",
    journal: "The Electronic Journal of Combinatorics",
    year: "2016",
    author: "Rabern, Landon",
    title: "A Better Lower Bound on Average Degree of 4-List-Critical Graphs",
    ENTRYTYPE: "article",
    ID: "rabern2016better",
    bibtex:
      "@article{rabern2016better,\n    url = {https://doi.org/10.37236/5971},\n    doi = {10.37236/5971},\n    pages = {P3.37},\n    number = {3},\n    volume = {23},\n    journal = {The Electronic Journal of Combinatorics},\n    year = {2016},\n    author = {Rabern, Landon},\n    title = {A Better Lower Bound on Average Degree of 4-List-Critical Graphs}\n}\n\n",
  },
  {
    url: "https://doi.org/10.37236/4626",
    doi: "10.37236/4626",
    pages: "P2.1",
    number: "2",
    volume: "22",
    journal: "The Electronic Journal of Combinatorics",
    year: "2015",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "A Note on Coloring Vertex-Transitive Graphs",
    ENTRYTYPE: "article",
    ID: "cranston2015note",
    bibtex:
      "@article{cranston2015note,\n    url = {https://doi.org/10.37236/4626},\n    doi = {10.37236/4626},\n    pages = {P2.1},\n    number = {2},\n    volume = {22},\n    journal = {The Electronic Journal of Combinatorics},\n    year = {2015},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {A Note on Coloring Vertex-Transitive Graphs}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1002/jgt.21847",
    doi: "10.1002/jgt.21847",
    pages: "199--225",
    number: "3",
    volume: "80",
    journal: "Journal of Graph Theory",
    year: "2015",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title: "Brooks' Theorem and beyond",
    ENTRYTYPE: "article",
    ID: "cranston2015brooks",
    bibtex:
      "@article{cranston2015brooks,\n    url = {https://doi.org/10.1002/jgt.21847},\n    doi = {10.1002/jgt.21847},\n    pages = {199--225},\n    number = {3},\n    volume = {80},\n    journal = {Journal of Graph Theory},\n    year = {2015},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {Brooks' Theorem and beyond}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1016/j.ejc.2014.09.006",
    doi: "10.1016/j.ejc.2014.09.006",
    pages: "23--42",
    volume: "44",
    journal: "European Journal of Combinatorics",
    year: "2015",
    author: "Cranston, Daniel W.  and Rabern, Landon",
    title:
      "Coloring a graph with Delta-1 colors: Conjectures equivalent to the Borodin--Kostochka conjecture that appear weaker",
    ENTRYTYPE: "article",
    ID: "cranston2015coloring",
    bibtex:
      "@article{cranston2015coloring,\n    url = {https://doi.org/10.1016/j.ejc.2014.09.006},\n    doi = {10.1016/j.ejc.2014.09.006},\n    pages = {23--42},\n    volume = {44},\n    journal = {European Journal of Combinatorics},\n    year = {2015},\n    author = {Cranston, Daniel W.  and Rabern, Landon},\n    title = {Coloring a graph with $\\Delta$- 1 colors: Conjectures equivalent to the Borodin--Kostochka conjecture that appear weaker}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1137/130929515",
    doi: "10.1137/130929515",
    pages: "1792--1814",
    number: "4",
    volume: "29",
    journal: "SIAM Journal on Discrete Mathematics",
    year: "2015",
    author: "Cranston, Daniel W. and Rabern, Landon",
    title: "Graphs with chi = Delta Have Big Cliques",
    ENTRYTYPE: "article",
    ID: "cranston2015graphs",
    bibtex:
      "@article{cranston2015graphs,\n    url = {https://doi.org/10.1137/130929515},\n    doi = {10.1137/130929515},\n    pages = {1792--1814},\n    number = {4},\n    volume = {29},\n    journal = {SIAM Journal on Discrete Mathematics},\n    year = {2015},\n    author = {Cranston, Daniel W. and Rabern, Landon},\n    title = {Graphs with $\\chi$=$\\Delta$ Have Big Cliques}\n}\n\n",
  },
  {
    keyword: "unpublished",
    url: "https://arxiv.org/abs/1507.05600",
    journal: "arXiv:1507.05600",
    year: "2015",
    author: "Rabern, Landon",
    title: "Edge-coloring via fixable subgraphs",
    ENTRYTYPE: "article",
    ID: "rabern2015edge",
    bibtex:
      "@article{rabern2015edge,\n    keyword = {unpublished},\n    url = {https://arxiv.org/abs/1507.05600},\n    journal = {arXiv:1507.05600},\n    year = {2015},\n    author = {Rabern, Landon},\n    title = {Edge-coloring via fixable subgraphs}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1016/j.disc.2013.12.010",
    doi: "10.1016/j.disc.2013.12.010",
    pages: "87--91",
    volume: "320",
    journal: "Discrete Mathematics",
    year: "2014",
    author: "Rabern, Landon",
    title: "A game generalizing Hall\u2019s Theorem",
    ENTRYTYPE: "article",
    ID: "rabern2014game",
    bibtex:
      "@article{rabern2014game,\n    url = {https://doi.org/10.1016/j.disc.2013.12.010},\n    doi = {10.1016/j.disc.2013.12.010},\n    pages = {87--91},\n    volume = {320},\n    journal = {Discrete Mathematics},\n    year = {2014},\n    author = {Rabern, Landon},\n    title = {A game generalizing Hall\u2019s Theorem}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1002/jgt.21768",
    doi: "10.1002/jgt.21768",
    pages: "323--340",
    number: "4",
    volume: "76",
    journal: "Journal of Graph Theory",
    year: "2014",
    author: "Rabern, Landon",
    title: "Coloring graphs with dense neighborhoods",
    ENTRYTYPE: "article",
    ID: "rabern2014coloring",
    bibtex:
      "@article{rabern2014coloring,\n    url = {https://doi.org/10.1002/jgt.21768},\n    doi = {10.1002/jgt.21768},\n    pages = {323--340},\n    number = {4},\n    volume = {76},\n    journal = {Journal of Graph Theory},\n    year = {2014},\n    author = {Rabern, Landon},\n    title = {Coloring graphs with dense neighborhoods}\n}\n\n",
  },
  {
    url: "https://doi.org/10.7151/dmgt.1721",
    doi: "10.7151/dmgt.1721",
    pages: "633--634",
    number: "3",
    volume: "34",
    publisher: "Sciendo",
    journal: "Discussiones Mathematicae Graph Theory",
    year: "2014",
    author: "Rabern, Landon",
    title: "A different short proof of Brooks\u2019 theorem",
    ENTRYTYPE: "article",
    ID: "rabern2014different",
    bibtex:
      "@article{rabern2014different,\n    url = {https://doi.org/10.7151/dmgt.1721},\n    doi = {10.7151/dmgt.1721},\n    pages = {633--634},\n    number = {3},\n    volume = {34},\n    publisher = {Sciendo},\n    journal = {Discussiones Mathematicae Graph Theory},\n    year = {2014},\n    author = {Rabern, Landon},\n    title = {A different short proof of Brooks\u2019 theorem}\n}\n\n",
  },
  {
    keyword: "unpublished",
    url: "https://landon.github.io/graphdata/Papers/K8.pdf",
    journal: "unpublished",
    year: "2014",
    author: "Rabern, Landon",
    title: "The list-chromatic index of K_8 and K_10",
    ENTRYTYPE: "article",
    ID: "rabern2014list",
    bibtex:
      "@article{rabern2014list,\n    keyword = {unpublished},\n    url = {https://landon.github.io/graphdata/Papers/K8.pdf},\n    journal = {unpublished},\n    year = {2014},\n    author = {Rabern, Landon},\n    title = {The list-chromatic index of K$_8$ and K$_{10}$}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1137/12088015X",
    doi: "10.1137/12088015X",
    pages: "534--549",
    number: "1",
    volume: "27",
    journal: "SIAM Journal on Discrete Mathematics",
    year: "2013",
    author: "Cranston, Daniel W. and Rabern, Landon",
    title: "Coloring claw-free graphs with Delta-1 colors",
    ENTRYTYPE: "article",
    ID: "cranston2013coloring",
    bibtex:
      "@article{cranston2013coloring,\n    url = {https://doi.org/10.1137/12088015X},\n    doi = {10.1137/12088015X},\n    pages = {534--549},\n    number = {1},\n    volume = {27},\n    journal = {SIAM Journal on Discrete Mathematics},\n    year = {2013},\n    author = {Cranston, Daniel W. and Rabern, Landon},\n    title = {Coloring claw-free graphs with $\\Delta$-1 colors}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1007/s10992-012-9246-2",
    doi: "10.1007/s10992-012-9246-2",
    pages: "727--765",
    number: "5",
    volume: "42",
    publisher: "Springer Verlag",
    journal: "Journal of Philosophical Logic",
    year: "2013",
    author: "Landon Rabern and Brian Rabern and Matthew Macauley",
    title: "Dangerous reference graphs and semantic paradoxes",
    ENTRYTYPE: "article",
    ID: "rabern2013dangerous",
    bibtex:
      "@article{rabern2013dangerous,\n    url = {https://doi.org/10.1007/s10992-012-9246-2},\n    doi = {10.1007/s10992-012-9246-2},\n    pages = {727--765},\n    number = {5},\n    volume = {42},\n    publisher = {Springer Verlag},\n    journal = {Journal of Philosophical Logic},\n    year = {2013},\n    author = {Landon Rabern and Brian Rabern and Matthew Macauley},\n    title = {Dangerous reference graphs and semantic paradoxes}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1016/j.disc.2013.01.007",
    doi: "10.1016/j.disc.2013.01.007",
    pages: "1028--1034",
    number: "9",
    volume: "313",
    journal: "Discrete Mathematics",
    year: "2013",
    author: "Rabern, Landon",
    title: "Partitioning and coloring graphs with degree constraints",
    ENTRYTYPE: "article",
    ID: "rabern2013partitioning",
    bibtex:
      "@article{rabern2013partitioning,\n    url = {https://doi.org/10.1016/j.disc.2013.01.007},\n    doi = {10.1016/j.disc.2013.01.007},\n    pages = {1028--1034},\n    number = {9},\n    volume = {313},\n    journal = {Discrete Mathematics},\n    year = {2013},\n    author = {Rabern, Landon},\n    title = {Partitioning and coloring graphs with degree constraints}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1002/jgt.21634",
    doi: "10.1002/jgt.21634",
    pages: "123--127",
    number: "2",
    volume: "72",
    journal: "Journal of Graph Theory",
    year: "2013",
    author: "Rabern, Landon",
    title: "Destroying noncomplete regular components in graph partitions",
    ENTRYTYPE: "article",
    ID: "rabern2013destroying",
    bibtex:
      "@article{rabern2013destroying,\n    url = {https://doi.org/10.1002/jgt.21634},\n    doi = {10.1002/jgt.21634},\n    pages = {123--127},\n    number = {2},\n    volume = {72},\n    journal = {Journal of Graph Theory},\n    year = {2013},\n    author = {Rabern, Landon},\n    title = {Destroying noncomplete regular components in graph partitions}\n}\n\n",
  },
  {
    school: "Arizona State University",
    note: "Supervisor: Hal Kierstead",
    url: "https://repository.asu.edu/attachments/110259/content/Rabern_asu_0010E_12641.pdf",
    year: "2013",
    author: "Rabern, Landon",
    title: "Coloring Graphs from Almost Maximum Degree Sized Palettes",
    ENTRYTYPE: "phdthesis",
    ID: "rabern2013coloring",
    bibtex:
      "@phdthesis{rabern2013coloring,\n    school = {Arizona State University},\n    note = {Supervisor: Hal Kierstead},\n    url = {https://repository.asu.edu/attachments/110259/content/Rabern_asu_0010E_12641.pdf},\n    year = {2013},\n    author = {Rabern, Landon},\n    title = {Coloring Graphs from Almost Maximum Degree Sized Palettes}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1016/j.disc.2011.12.014",
    doi: "10.1016/j.disc.2011.12.014",
    pages: "1273--1281",
    number: "6",
    volume: "312",
    journal: "Discrete Mathematics",
    year: "2012",
    author: "Kostochka, Alexandr V. and Rabern, Landon and Stiebitz, Michael",
    title: "Graphs with chromatic number close to maximum degree",
    ENTRYTYPE: "article",
    ID: "kostochka2012graphs",
    bibtex:
      "@article{kostochka2012graphs,\n    url = {https://doi.org/10.1016/j.disc.2011.12.014},\n    doi = {10.1016/j.disc.2011.12.014},\n    pages = {1273--1281},\n    number = {6},\n    volume = {312},\n    journal = {Discrete Mathematics},\n    year = {2012},\n    author = {Kostochka, Alexandr V. and Rabern, Landon and Stiebitz, Michael},\n    title = {Graphs with chromatic number close to maximum degree}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1016/j.jctb.2011.05.003",
    doi: "10.1016/j.jctb.2011.05.003",
    pages: "126--130",
    number: "1",
    volume: "102",
    journal: "Journal of Combinatorial Theory, Series B",
    year: "2012",
    author: "Rabern, Landon",
    title: "Delta-Critical graphs with small high vertex cliques",
    ENTRYTYPE: "article",
    ID: "rabern2012delta",
    bibtex:
      "@article{rabern2012delta,\n    url = {https://doi.org/10.1016/j.jctb.2011.05.003},\n    doi = {10.1016/j.jctb.2011.05.003},\n    pages = {126--130},\n    number = {1},\n    volume = {102},\n    journal = {Journal of Combinatorial Theory, Series B},\n    year = {2012},\n    author = {Rabern, Landon},\n    title = {$\\Delta$-Critical graphs with small high vertex cliques}\n}\n\n",
  },
  {
    url: "https://doi.org/10.37236/632",
    doi: "10.37236/632",
    pages: "P145",
    number: "1",
    volume: "18",
    journal: "The Electronic Journal of Combinatorics",
    year: "2011",
    author: "Rabern, Landon",
    title: "A strengthening of Brooks' Theorem for line graphs",
    ENTRYTYPE: "article",
    ID: "rabern2011strengthening",
    bibtex:
      "@article{rabern2011strengthening,\n    url = {https://doi.org/10.37236/632},\n    doi = {10.37236/632},\n    pages = {P145},\n    number = {1},\n    volume = {18},\n    journal = {The Electronic Journal of Combinatorics},\n    year = {2011},\n    author = {Rabern, Landon},\n    title = {A strengthening of Brooks' Theorem for line graphs}\n}\n\n",
  },
  {
    keyword: "unpublished",
    url: "https://arxiv.org/abs/1102.1021",
    journal: "arXiv:1102.1021",
    year: "2011",
    author: "Rabern, Landon",
    title: "{An improvement on Brooks' theorem}",
    ENTRYTYPE: "article",
    ID: "rabern2011improvement",
    bibtex:
      "@article{rabern2011improvement,\n    keyword = {unpublished},\n    url = {https://arxiv.org/abs/1102.1021},\n    journal = {arXiv:1102.1021},\n    year = {2011},\n    author = {Rabern, Landon},\n    title = {{An improvement on Brooks' theorem}}\n}\n\n",
  },
  {
    keyword: "unpublished",
    url: "https://arxiv.org/abs/1107.1735",
    journal: "arXiv:1107.1735",
    year: "2011",
    author: "Rabern, Landon",
    title: "A note on vertex partitions",
    ENTRYTYPE: "article",
    ID: "rabern2011note",
    bibtex:
      "@article{rabern2011note,\n    keyword = {unpublished},\n    url = {https://arxiv.org/abs/1107.1735},\n    journal = {arXiv:1107.1735},\n    year = {2011},\n    author = {Rabern, Landon},\n    title = {A note on vertex partitions}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1002/jgt.20487",
    doi: "10.1002/jgt.20487",
    pages: "32--37",
    number: "1",
    volume: "66",
    journal: "Journal of Graph Theory",
    year: "2011",
    author: "Rabern, Landon",
    title: "On hitting all maximum cliques with an independent set",
    ENTRYTYPE: "article",
    ID: "rabern2011hitting",
    bibtex:
      "@article{rabern2011hitting,\n    url = {https://doi.org/10.1002/jgt.20487},\n    doi = {10.1002/jgt.20487},\n    pages = {32--37},\n    number = {1},\n    volume = {66},\n    journal = {Journal of Graph Theory},\n    year = {2011},\n    author = {Rabern, Landon},\n    title = {On hitting all maximum cliques with an independent set}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1093/analys/68.2.105",
    doi: "10.1093/analys/68.2.105",
    pages: "105--112",
    number: "2",
    volume: "68",
    journal: "Analysis",
    year: "2008",
    author: "Brian Rabern and Landon Rabern",
    title: "A simple solution to the hardest logic puzzle ever",
    ENTRYTYPE: "article",
    ID: "rabern2008simple",
    bibtex:
      "@article{rabern2008simple,\n    url = {https://doi.org/10.1093/analys/68.2.105},\n    doi = {10.1093/analys/68.2.105},\n    pages = {105--112},\n    number = {2},\n    volume = {68},\n    journal = {Analysis},\n    year = {2008},\n    author = {Brian Rabern and Landon Rabern},\n    title = {A simple solution to the hardest logic puzzle ever}\n}\n\n",
  },
  {
    url: "http://gtn.kazlow.info/GTN55.pdf",
    pages: "14--24",
    journal: "Graph Theory Notes of New York LV",
    year: "2008",
    author: "Gernet, Dieter and Rabern, Landon",
    title:
      "A computerized system for graph theory, illustrated by partial proofs for graph-coloring problems",
    ENTRYTYPE: "article",
    ID: "gernet2008computerized",
    bibtex:
      "@article{gernet2008computerized,\n    url = {http://gtn.kazlow.info/GTN55.pdf},\n    pages = {14--24},\n    journal = {Graph Theory Notes of New York LV},\n    year = {2008},\n    author = {Gernet, Dieter and Rabern, Landon},\n    title = {A computerized system for graph theory, illustrated by partial proofs for graph-coloring problems}\n}\n\n",
  },
  {
    keyword: "unpublished",
    url: "https://arxiv.org/abs/0808.0844",
    journal: "arXiv:0808.0844",
    year: "2008",
    author: "Matthew Macauley and Brian Rabern and Landon Rabern",
    title: "{A Novel Proof of the Heine-Borel Theorem}",
    ENTRYTYPE: "article",
    ID: "macauley2008novel",
    bibtex:
      "@article{macauley2008novel,\n    keyword = {unpublished},\n    url = {https://arxiv.org/abs/0808.0844},\n    journal = {arXiv:0808.0844},\n    year = {2008},\n    author = {Matthew Macauley and Brian Rabern and Landon Rabern},\n    title = {{A Novel Proof of the Heine-Borel Theorem}}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1137/060659193",
    doi: "10.1137/060659193",
    pages: "820--827",
    number: "2",
    volume: "22",
    journal: "SIAM Journal on Discrete Mathematics",
    year: "2008",
    author: "Rabern, Landon",
    title: "A note on Reed's conjecture",
    ENTRYTYPE: "article",
    ID: "rabern2008note",
    bibtex:
      "@article{rabern2008note,\n    url = {https://doi.org/10.1137/060659193},\n    doi = {10.1137/060659193},\n    pages = {820--827},\n    number = {2},\n    volume = {22},\n    journal = {SIAM Journal on Discrete Mathematics},\n    year = {2008},\n    author = {Rabern, Landon},\n    title = {A note on Reed's conjecture}\n}\n\n",
  },
  {
    url: "http://match.pmf.kg.ac.rs/electronic_versions/Match58/n2/match58n2_445-460.pdf",
    pages: "445--460",
    number: "2",
    volume: "58",
    journal: "MATCH Commun. Math. Comput. Chem",
    year: "2007",
    author: "Gernert, Dieter and Rabern, Landon",
    title:
      "A knowledge-based system for graph theory, demonstrated by partial proofs for graph-colouring problems",
    ENTRYTYPE: "article",
    ID: "gernert2007knowledge",
    bibtex:
      "@article{gernert2007knowledge,\n    url = {http://match.pmf.kg.ac.rs/electronic_versions/Match58/n2/match58n2_445-460.pdf},\n    pages = {445--460},\n    number = {2},\n    volume = {58},\n    journal = {MATCH Commun. Math. Comput. Chem},\n    year = {2007},\n    author = {Gernert, Dieter and Rabern, Landon},\n    title = {A knowledge-based system for graph theory, demonstrated by partial proofs for graph-colouring problems}\n}\n\n",
  },
  {
    url: "https://doi.org/10.37236/1023",
    doi: "10.37236/1023",
    pages: "N22",
    volume: "14",
    journal: "The Electronic Journal of Combinatorics",
    year: "2007",
    author: "Rabern, Landon",
    title:
      "The Borodin-Kostochka conjecture for graphs containing a doubly critical edge",
    ENTRYTYPE: "article",
    ID: "rabern2007borodin",
    bibtex:
      "@article{rabern2007borodin,\n    url = {https://doi.org/10.37236/1023},\n    doi = {10.37236/1023},\n    pages = {N22},\n    volume = {14},\n    journal = {The Electronic Journal of Combinatorics},\n    year = {2007},\n    author = {Rabern, Landon},\n    title = {The Borodin-Kostochka conjecture for graphs containing a doubly critical edge}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1016/j.jpaa.2006.09.001",
    doi: "10.1016/j.jpaa.2006.09.001",
    pages: "137--140",
    number: "1",
    volume: "210",
    journal: "Journal of Pure and Applied Algebra",
    year: "2007",
    author: "Rabern, Landon",
    title: 'Applying Gr{\\"o}bner basis techniques to group theory',
    ENTRYTYPE: "article",
    ID: "rabern2007applying",
    bibtex:
      '@article{rabern2007applying,\n    url = {https://doi.org/10.1016/j.jpaa.2006.09.001},\n    doi = {10.1016/j.jpaa.2006.09.001},\n    pages = {137--140},\n    number = {1},\n    volume = {210},\n    journal = {Journal of Pure and Applied Algebra},\n    year = {2007},\n    author = {Rabern, Landon},\n    title = {Applying Gr{\\"o}bner basis techniques to group theory}\n}\n\n',
  },
  {
    keyword: "unpublished",
    url: "https://arxiv.org/abs/0707.1069",
    journal: "arXiv:0707.1069",
    year: "2007",
    author: "Rabern, Landon",
    title: "Coloring and The Lonely Graph",
    ENTRYTYPE: "article",
    ID: "rabern2007coloring",
    bibtex:
      "@article{rabern2007coloring,\n    keyword = {unpublished},\n    url = {https://arxiv.org/abs/0707.1069},\n    journal = {arXiv:0707.1069},\n    year = {2007},\n    author = {Rabern, Landon},\n    title = {Coloring and The Lonely Graph}\n}\n\n",
  },
  {
    keyword: "unpublished",
    url: "https://arxiv.org/abs/0708.2956",
    journal: "arXiv:0708.2956",
    year: "2007",
    author: "Rabern, Landon",
    title:
      "At Least Half Of All Graphs Satisfy $\\chi\\leq {\\frac{1}{4}}\\omega+ {\\frac{3}{4}}\\Delta + 1$",
    ENTRYTYPE: "article",
    ID: "rabern2007least",
    bibtex:
      "@article{rabern2007least,\n    keyword = {unpublished},\n    url = {https://arxiv.org/abs/0708.2956},\n    journal = {arXiv:0708.2956},\n    year = {2007},\n    author = {Rabern, Landon},\n    title = {At Least Half Of All Graphs Satisfy $\\chi\\leq {\\frac{1}{4}}\\omega+ {\\frac{3}{4}}\\Delta + 1$}\n}\n\n",
  },
  {
    url: "https://doi.org/10.1137/050626545",
    doi: "10.1137/050626545",
    pages: "529--535",
    number: "2",
    volume: "20",
    journal: "SIAM Journal on Discrete Mathematics",
    year: "2006",
    author: "Rabern, Landon",
    title: "On graph associations",
    ENTRYTYPE: "article",
    ID: "rabern2006graph",
    bibtex:
      "@article{rabern2006graph,\n    url = {https://doi.org/10.1137/050626545},\n    doi = {10.1137/050626545},\n    pages = {529--535},\n    number = {2},\n    volume = {20},\n    journal = {SIAM Journal on Discrete Mathematics},\n    year = {2006},\n    author = {Rabern, Landon},\n    title = {On graph associations}\n}\n\n",
  },
  {
    keyword: "unpublished",
    url: "https://arxiv.org/abs/math/0606632",
    journal: "arXiv:math/0606632",
    year: "2006",
    author: "Rabern, Landon",
    title: "New upper bounds on the chromatic number of a graph",
    ENTRYTYPE: "article",
    ID: "rabern2006new",
    bibtex:
      "@article{rabern2006new,\n    keyword = {unpublished},\n    url = {https://arxiv.org/abs/math/0606632},\n    journal = {arXiv:math/0606632},\n    year = {2006},\n    author = {Rabern, Landon},\n    title = {New upper bounds on the chromatic number of a graph}\n}\n\n",
  },
  {
    keyword: "unpublished",
    url: "https://arxiv.org/abs/math/0512291",
    journal: "arXiv:math/0512291",
    year: "2005",
    author: "Rabern, Landon",
    title:
      "Some bounds on convex combinations of omega and chi for decompositions into many parts",
    ENTRYTYPE: "article",
    ID: "rabern2005some",
    bibtex:
      "@article{rabern2005some,\n    keyword = {unpublished},\n    url = {https://arxiv.org/abs/math/0512291},\n    journal = {arXiv:math/0512291},\n    year = {2005},\n    author = {Rabern, Landon},\n    title = {Some bounds on convex combinations of $\\omega$ and $\\chi$ for decompositions into many parts}\n}\n\n",
  },
  {
    url: "https://scholar.rose-hulman.edu/rhumj/vol4/iss1/3/",
    volume: "4.1",
    journal: "Rose-Hulman Undergraduate Mathematics Journal",
    year: "2003",
    author: "Rabern, Landon",
    title: "Properties of Magic Squares of Squares",
    ENTRYTYPE: "article",
    ID: "rabern2003properties",
    bibtex:
      "@article{rabern2003properties,\n    url = {https://scholar.rose-hulman.edu/rhumj/vol4/iss1/3/},\n    volume = {4.1},\n    journal = {Rose-Hulman Undergraduate Mathematics Journal},\n    year = {2003},\n    author = {Rabern, Landon},\n    title = {Properties of Magic Squares of Squares}\n}\n\n",
  },
];

export default landonPublicationsData;

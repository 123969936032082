const brianPublicationsData = [
  {
    year: "2023",
    volume: "44",
    title: "A Puzzle About Scope for Restricted Deontic Modals",
    pages: "8--10",
    doi: "10.7358/snip-2023-044-rato",
    journal: "Snippets",
    author: "Brian Rabern and Patrick Todd",
    ENTRYTYPE: "article",
    ID: "Rabern2023-RABAPA-3",
    bibtex:
      "@article{Rabern2023-RABAPA-3,\n    year = {2023},\n    volume = {44},\n    title = {A Puzzle About Scope for Restricted Deontic Modals},\n    pages = {8--10},\n    journal = {Snippets},\n    author = {Brian Rabern and Patrick Todd}\n}\n\n",
    url: "https://philpapers.org/rec/RABAPA-3",
  },
  {
    year: "2023",
    volume: "52",
    title: "Pure Quotation in Linguistic Context",
    pages: "393--413",
    number: "2",
    journal: "Journal of Philosophical Logic",
    doi: "10.1007/s10992-022-09675-3",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2023-RABPQI",
    bibtex:
      "@article{Rabern2023-RABPQI,\n    year = {2023},\n    volume = {52},\n    title = {Pure Quotation in Linguistic Context},\n    pages = {393--413},\n    number = {2},\n    journal = {Journal of Philosophical Logic},\n    doi = {10.1007/s10992-022-09675-3},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABPQI",
  },
  {
    year: "2023",
    volume: "180",
    title: "Resisting the Epistemic Argument for Compatibilism",
    publisher: "Springer Verlag",
    pages: "1743--1767",
    number: "5",
    journal: "Philosophical Studies",
    doi: "10.1007/s11098-023-01946-2",
    author: "Patrick Todd and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Todd2023-TODRTE-2",
    bibtex:
      "@article{Todd2023-TODRTE-2,\n    year = {2023},\n    volume = {180},\n    title = {Resisting the Epistemic Argument for Compatibilism},\n    publisher = {Springer Verlag},\n    pages = {1743--1767},\n    number = {5},\n    journal = {Philosophical Studies},\n    doi = {10.1007/s11098-023-01946-2},\n    author = {Patrick Todd and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/TODRTE-2",
  },
  {
    year: "2023",
    volume: "9",
    title: "Against Fregean Quantification",
    pages: "971--1007",
    number: "37",
    journal: "Ergo: An Open Access Journal of Philosophy",
    doi: "10.3998/ergo.2906",
    author: "Bryan Pickel and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Pickel2023-PICAFQ",
    bibtex:
      "@article{Pickel2023-PICAFQ,\n    year = {2023},\n    volume = {9},\n    title = {Against Fregean Quantification (1st edition)},\n    pages = {971--1007},\n    number = {37},\n    journal = {Ergo: An Open Access Journal of Philosophy},\n    doi = {10.3998/ergo.2906},\n    author = {Bryan Pickel and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/PICAFQ",
  },
  {
    year: "2023",
    volume: "83",
    title: "The Onus in 'Ought'",
    pages: "13--21",
    number: "1",
    journal: "Analysis",
    doi: "10.1093/analys/anac051",
    author: "Mahrad Almotahari and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Almotahari2023-ALMTOI",
    bibtex:
      "@article{Almotahari2023-ALMTOI,\n    year = {2023},\n    volume = {83},\n    title = {The Onus in 'Ought'},\n    pages = {13--21},\n    number = {1},\n    journal = {Analysis},\n    doi = {10.1093/analys/anac051},\n    author = {Mahrad Almotahari and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/ALMTOI",
  },
  {
    year: "2022",
    volume: "15",
    title: "Scorekeeping in a Chess Game",
    number: "12",
    journal: "Semantics and Pragmatics",
    author: "Bryan Pickel and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Pickel2022-PICSIA",
    bibtex:
      "@article{Pickel2022-PICSIA,\n    year = {2022},\n    volume = {15},\n    title = {Scorekeeping in a Chess Game},\n    number = {12},\n    journal = {Semantics and Pragmatics},\n    author = {Bryan Pickel and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/PICSIA",
  },
  {
    year: "2022",
    volume: "59",
    title: "The Paradox of Self-Blame",
    pages: "111--125",
    number: "2",
    journal: "American Philosophical Quarterly",
    doi: "10.5406/21521123.59.2.01",
    author: "Patrick Todd and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Todd2022-TODTPO-34",
    bibtex:
      "@article{Todd2022-TODTPO-34,\n    year = {2022},\n    volume = {59},\n    title = {The Paradox of Self-Blame},\n    pages = {111--125},\n    number = {2},\n    journal = {American Philosophical Quarterly},\n    doi = {10.5406/21521123.59.2.01},\n    author = {Patrick Todd and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/TODTPO-34",
  },
  {
    year: "2021",
    volume: "64",
    title: "Binding Bound Variables in Epistemic Contexts",
    publisher: "Routledge",
    pages: "533--563",
    number: "5-6",
    journal: "Inquiry: An Interdisciplinary Journal of Philosophy",
    doi: "10.1080/0020174x.2018.1470568",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2021-RABBBV",
    bibtex:
      "@article{Rabern2021-RABBBV,\n    year = {2021},\n    volume = {64},\n    title = {Binding Bound Variables in Epistemic Contexts},\n    publisher = {Routledge},\n    pages = {533--563},\n    number = {5-6},\n    journal = {Inquiry: An Interdisciplinary Journal of Philosophy},\n    doi = {10.1080/0020174x.2018.1470568},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABBBV",
  },
  {
    year: "2021",
    title: "Semantic Monsters",
    publisher: "Routledge",
    pages: "515--532",
    editor: "Heimir Geirsson and Stephen Biggs",
    booktitle: "The Routledge Handbook of Linguistic Reference",
    author: "Brian Rabern",
    ENTRYTYPE: "incollection",
    ID: "Rabern2021-RABSM",
    bibtex:
      "@incollection{Rabern2021-RABSM,\n    year = {2021},\n    title = {Semantic Monsters},\n    publisher = {Routledge},\n    pages = {515--532},\n    editor = {Heimir Geirsson and Stephen Biggs},\n    booktitle = {The Routledge Handbook of Linguistic Reference},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABSM",
  },
  {
    year: "2021",
    volume: "55",
    title: "Future Contingents and the Logic of Temporal Omniscience",
    pages: "102--127",
    number: "1",
    journal: "Noûs",
    doi: "10.1111/nous.12294",
    author: "Patrick Todd and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Todd2021-TODFCA-2",
    bibtex:
      "@article{Todd2021-TODFCA-2,\n    year = {2021},\n    volume = {55},\n    title = {Future Contingents and the Logic of Temporal Omniscience},\n    pages = {102--127},\n    number = {1},\n    journal = {No\\^{u}s},\n    doi = {10.1111/nous.12294},\n    author = {Patrick Todd and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/TODFCA-2",
  },
  {
    year: "2021",
    volume: "44",
    title: "The Myth of Occurrence-Based Semantics",
    publisher: "Springer Verlag",
    pages: "813--837",
    journal: "Linguistics and Philosophy",
    doi: "10.1007/s10988-020-09302-x",
    author: "Bryan Pickel and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Pickel2021-PICTMO-14",
    bibtex:
      "@article{Pickel2021-PICTMO-14,\n    year = {2021},\n    volume = {44},\n    title = {The Myth of Occurrence-Based Semantics},\n    publisher = {Springer Verlag},\n    pages = {813--837},\n    journal = {Linguistics and Philosophy},\n    doi = {10.1007/s10988-020-09302-x},\n    author = {Bryan Pickel and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/PICTMO-14",
  },
  {
    year: "2021",
    volume: "178",
    title: "Frege and Saving Substitution",
    publisher: "Springer Verlag",
    pages: "2687--2697",
    number: "8",
    journal: "Philosophical Studies",
    doi: "10.1007/s11098-020-01576-y",
    author: "Bryan Pickel and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Pickel2021-PICFAS",
    bibtex:
      "@article{Pickel2021-PICFAS,\n    year = {2021},\n    volume = {178},\n    title = {Frege and Saving Substitution},\n    publisher = {Springer Verlag},\n    pages = {2687--2697},\n    number = {8},\n    journal = {Philosophical Studies},\n    doi = {10.1007/s11098-020-01576-y},\n    author = {Bryan Pickel and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/PICFAS",
  },
  {
    year: "2020",
    volume: "39",
    title: "Might Generics",
    pages: "8--9",
    journal: "Snippets",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2020-RABMG",
    bibtex:
      "@article{Rabern2020-RABMG,\n    year = {2020},\n    volume = {39},\n    title = {Might Generics},\n    pages = {8--9},\n    journal = {Snippets},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABMG",
  },
  {
    year: "2019",
    volume: "98",
    title: "Monsters and the Theoretical Role of Context",
    pages: "392--416",
    number: "2",
    journal: "Philosophy and Phenomenological Research",
    doi: "10.1111/phpr.12449",
    author: "Brian Rabern and Derek Ball",
    ENTRYTYPE: "article",
    ID: "Rabern2019-BRIMAT-8",
    bibtex:
      "@article{Rabern2019-BRIMAT-8,\n    year = {2019},\n    volume = {98},\n    title = {Monsters and the Theoretical Role of Context},\n    pages = {392--416},\n    number = {2},\n    journal = {Philosophy and Phenomenological Research},\n    doi = {10.1111/phpr.12449},\n    author = {Brian Rabern and Derek Ball}\n}\n\n",
    url: "https://philpapers.org/rec/BRIMAT-8",
  },
  {
    year: "2018",
    title: "Reviving the Parameter Revolution in Semantics",
    publisher: "Oxford University Press",
    pages: "138--171",
    editor: "Derek Ball and Brian Rabern",
    booktitle: "The Science of Meaning",
    author: "Bryan Pickel and Brian Rabern and Josh Dever",
    ENTRYTYPE: "incollection",
    ID: "Pickel2018-PICRTP",
    bibtex:
      "@incollection{Pickel2018-PICRTP,\n    year = {2018},\n    title = {Reviving the Parameter Revolution in Semantics},\n    publisher = {Oxford University Press},\n    pages = {138--171},\n    editor = {Derek Ball and Brian Rabern},\n    booktitle = {The Science of Meaning},\n    author = {Bryan Pickel and Brian Rabern and Josh Dever}\n}\n\n",
    url: "https://philpapers.org/rec/PICRTP",
  },
  {
    year: "2017",
    volume: "45",
    title: "A Bridge From Semantic Value to Content",
    pages: "181--207",
    number: "2",
    journal: "Philosophical Topics",
    doi: "10.5840/philtopics201745219",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2017-BRIABF-2",
    bibtex:
      "@article{Rabern2017-BRIABF-2,\n    year = {2017},\n    volume = {45},\n    title = {A Bridge From Semantic Value to Content},\n    pages = {181--207},\n    number = {2},\n    journal = {Philosophical Topics},\n    doi = {10.5840/philtopics201745219},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/BRIABF-2",
  },
  {
    year: "2017",
    title: "Index, Context, and the Content of Knowledge",
    publisher: "Routledge",
    pages: "465--479",
    editor: "Jonathan Jenkins Ichikawa",
    booktitle: "The Routledge Handbook of Epistemic Contextualism",
    author: "Brian Rabern",
    ENTRYTYPE: "incollection",
    ID: "Rabern2017-RABICA",
    bibtex:
      "@incollection{Rabern2017-RABICA,\n    year = {2017},\n    title = {Index, Context, and the Content of Knowledge},\n    publisher = {Routledge},\n    pages = {465--479},\n    editor = {Jonathan Jenkins Ichikawa},\n    booktitle = {The Routledge Handbook of Epistemic Contextualism},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABICA",
  },
  {
    year: "2017",
    volume: "126",
    title: "Against the Russellian Open Future",
    pages: "1217--1237",
    number: "504",
    journal: "Mind",
    doi: "10.1093/mind/fzv189",
    author: "Anders J. Schoubye and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Schoubye2017-SCHATR-4",
    bibtex:
      "@article{Schoubye2017-SCHATR-4,\n    year = {2017},\n    volume = {126},\n    title = {Against the Russellian Open Future},\n    pages = {1217--1237},\n    number = {504},\n    journal = {Mind},\n    doi = {10.1093/mind/fzv189},\n    author = {Anders J. Schoubye and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/SCHATR-4",
  },
  {
    year: "2017",
    volume: "46",
    title: "Does Semantic Relationism Solve Frege's Puzzle?",
    publisher: "Springer Verlag",
    pages: "97--118",
    number: "1",
    journal: "Journal of Philosophical Logic",
    doi: "10.1007/s10992-016-9420-z",
    author: "Bryan Pickel and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Pickel2017-PICDSR",
    bibtex:
      "@article{Pickel2017-PICDSR,\n    year = {2017},\n    volume = {46},\n    title = {Does Semantic Relationism Solve Frege?s Puzzle?},\n    publisher = {Springer Verlag},\n    pages = {97--118},\n    number = {1},\n    journal = {Journal of Philosophical Logic},\n    doi = {10.1007/s10992-016-9420-z},\n    author = {Bryan Pickel and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/PICDSR",
  },
  {
    year: "2016",
    volume: "9",
    title:
      "The History of the Use of ⟦.⟧-Notation in Natural Language Semantics",
    number: "12",
    journal: "Semantics and Pragmatics",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2016-RABTHO-2",
    bibtex:
      "@article{Rabern2016-RABTHO-2,\n    year = {2016},\n    volume = {9},\n    title = {The History of the Use of ?.?-Notation in Natural Language Semantics},\n    number = {12},\n    journal = {Semantics and Pragmatics},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABTHO-2",
  },
  {
    year: "2016",
    volume: "45",
    title: "Well Founding Grounding Grounding",
    publisher: "Springer Verlag",
    pages: "349--379",
    number: "4",
    journal: "Journal of Philosophical Logic",
    doi: "10.1007/s10992-015-9376-4",
    author: "Gabriel Oak Rabin and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabin2016-RABWFG",
    bibtex:
      "@article{Rabin2016-RABWFG,\n    year = {2016},\n    volume = {45},\n    title = {Well Founding Grounding Grounding},\n    publisher = {Springer Verlag},\n    pages = {349--379},\n    number = {4},\n    journal = {Journal of Philosophical Logic},\n    doi = {10.1007/s10992-015-9376-4},\n    author = {Gabriel Oak Rabin and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABWFG",
  },
  {
    year: "2016",
    volume: "113",
    title: "The Antinomy of the Variable: A Tarskian Resolution",
    pages: "137--170",
    number: "3",
    journal: "Journal of Philosophy",
    doi: "10.5840/jphil201611338",
    author: "Bryan Pickel and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Pickel2016-PICTAO-7",
    bibtex:
      "@article{Pickel2016-PICTAO-7,\n    year = {2016},\n    volume = {113},\n    title = {The Antinomy of the Variable: A Tarskian Resolution},\n    pages = {137--170},\n    number = {3},\n    journal = {Journal of Philosophy},\n    doi = {10.5840/jphil201611338},\n    author = {Bryan Pickel and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/PICTAO-7",
  },
  {
    year: "2015",
    volume: "30",
    title: "Descriptions Which Have Grown Capital Letters",
    publisher: "Blackwell",
    pages: "292--319",
    number: "3",
    journal: "Mind and Language",
    doi: "10.1111/mila.12081",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2015-RABDWH",
    bibtex:
      "@article{Rabern2015-RABDWH,\n    year = {2015},\n    volume = {30},\n    title = {Descriptions Which Have Grown Capital Letters},\n    publisher = {Blackwell},\n    pages = {292--319},\n    number = {3},\n    journal = {Mind and Language},\n    doi = {10.1111/mila.12081},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABDWH",
  },
  {
    year: "2014",
    volume: "74",
    title: "Two-Dimensional Semantics and the Nesting Problem",
    pages: "210--224",
    number: "2",
    journal: "Analysis",
    doi: "10.1093/analys/anu032",
    author: "David J. Chalmers and Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Chalmers2014-CHATSA-5",
    bibtex:
      "@article{Chalmers2014-CHATSA-5,\n    year = {2014},\n    volume = {74},\n    title = {Two-Dimensional Semantics and the Nesting Problem},\n    pages = {210--224},\n    number = {2},\n    journal = {Analysis},\n    doi = {10.1093/analys/anu032},\n    author = {David J. Chalmers and Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/CHATSA-5",
  },
  {
    year: "2013",
    volume: "164",
    title: "Monsters in Kaplan's Logic of Demonstratives",
    publisher: "Springer Verlag",
    pages: "393--404",
    number: "2",
    journal: "Philosophical Studies",
    doi: "10.1007/s11098-012-9855-1",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2013-RABTMQ",
    bibtex:
      "@article{Rabern2013-RABTMQ,\n    year = {2013},\n    volume = {164},\n    title = {Monsters in Kaplan?s Logic of Demonstratives},\n    publisher = {Springer Verlag},\n    pages = {393--404},\n    number = {2},\n    journal = {Philosophical Studies},\n    doi = {10.1007/s11098-012-9855-1},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABTMQ",
  },
  {
    year: "2013",
    volume: "42",
    title: "Dangerous Reference Graphs and Semantic Paradoxes",
    publisher: "Springer Verlag",
    pages: "727--765",
    number: "5",
    journal: "Journal of Philosophical Logic",
    doi: "10.1007/s10992-012-9246-2",
    author: "Landon Rabern and Brian Rabern and Matthew Macauley",
    ENTRYTYPE: "article",
    ID: "Rabern2013-RABDRG",
    bibtex:
      "@article{Rabern2013-RABDRG,\n    year = {2013},\n    volume = {42},\n    title = {Dangerous Reference Graphs and Semantic Paradoxes},\n    publisher = {Springer Verlag},\n    pages = {727--765},\n    number = {5},\n    journal = {Journal of Philosophical Logic},\n    doi = {10.1007/s10992-012-9246-2},\n    author = {Landon Rabern and Brian Rabern and Matthew Macauley}\n}\n\n",
    url: "https://philpapers.org/rec/RABDRG",
  },
  {
    year: "2012",
    volume: "189",
    title:
      "Against the Identification of Assertoric Content with Compositional Value",
    publisher: "Springer Nature",
    pages: "75--96",
    number: "1",
    journal: "Synthese",
    doi: "10.1007/s11229-012-0096-9",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2012-RABATI",
    bibtex:
      "@article{Rabern2012-RABATI,\n    year = {2012},\n    volume = {189},\n    title = {Against the Identification of Assertoric Content with Compositional Value},\n    publisher = {Springer Nature},\n    pages = {75--96},\n    number = {1},\n    journal = {Synthese},\n    doi = {10.1007/s11229-012-0096-9},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABATI",
  },
  {
    year: "2012",
    title: "The Semantics of Contextual Shifting and Sensitivity",
    school: "The Australian National University",
    author: "Brian Rabern",
    ENTRYTYPE: "phdthesis",
    ID: "Rabern2012-BRIMAC-3",
    bibtex:
      "@phdthesis{Rabern2012-BRIMAC-3,\n    year = {2012},\n    title = {The Semantics of Contextual Shifting and Sensitivity},\n    school = {The Australian National University},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/BRIMAC-3",
  },
  {
    year: "2012",
    volume: "1",
    title: "Propositions and Multiple Indexing",
    pages: "116--124",
    number: "2",
    journal: "Thought: A Journal of Philosophy",
    doi: "10.1002/tht3.16",
    author: "Brian Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2012-RABPAM",
    bibtex:
      "@article{Rabern2012-RABPAM,\n    year = {2012},\n    volume = {1},\n    title = {Propositions and Multiple Indexing},\n    pages = {116--124},\n    number = {2},\n    journal = {Thought: A Journal of Philosophy},\n    doi = {10.1002/tht3.16},\n    author = {Brian Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABPAM",
  },
  {
    year: "2008",
    volume: "68",
    title: "A Simple Solution to the Hardest Logic Puzzle Ever",
    publisher: "Oxford University Press",
    pages: "105--112",
    number: "2",
    journal: "Analysis",
    doi: "10.1111/j.1467-8284.2007.00723.x",
    author: "Brian Rabern and Landon Rabern",
    ENTRYTYPE: "article",
    ID: "Rabern2008-RABASS",
    bibtex:
      "@article{Rabern2008-RABASS,\n    year = {2008},\n    volume = {68},\n    title = {A Simple Solution to the Hardest Logic Puzzle Ever},\n    publisher = {Oxford University Press},\n    pages = {105--112},\n    number = {2},\n    journal = {Analysis},\n    doi = {10.1111/j.1467-8284.2007.00723.x},\n    author = {Brian Rabern and Landon Rabern}\n}\n\n",
    url: "https://philpapers.org/rec/RABASS",
  },
];

export default brianPublicationsData;
